import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { rem, flex, is, position } from 'styled-tidy'
import debounce from 'lodash.debounce'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import linkCircle from 'lib/styles/link-circle'
import stopEvent from 'lib/stop-event'
import Layout from 'layouts/default'
import Image from 'components/image'
import ExternalLink from 'components/external-link'
import Timeline from 'components/icons/timeline'
import Close from 'components/icons/close'

const { primary, white, ghost, bone, black } = theme.colors

const Body = styled.div`
  margin: 0 auto;
  max-width: ${rem(1000)};

  ${media.xsmall`
    ${flex('row', 'flex-start', 'space-between')}
  `}
`

const Article = styled.article`
  text-align: left;
  max-width: ${rem(600)};
  width: 100%;

  h1 {
    text-align: center;
  }

  table {
    width: 100%;
  }

  ${media.xsmall`
    width: calc(100% - ${rem(96)});

    h1 {
      text-align: left;
    }
  `}

  ${media.small`
    width: calc(70% - ${rem(12)});
  `}

  ${media.medium`
    table {
      width: calc(100% - ${rem(16)});
    }
  `}
`

const Aside = styled.aside`
  ${flex('column', 'flex-start', 'flex-start')}
  ${linkCircle}
  user-select: none;

  @keyframes zoomIn {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .gatsby-image-wrapper {
    animation: zoomIn 500ms ease;
  }

  ${media.xsmall`
    text-align: left;
    top: ${rem(96)};
    width: ${rem(80)};
  `}

  ${media.small`
    width: calc(30% - ${rem(12)});
  `}
`

const Avatar = styled.div`
  margin: 0 auto ${rem(32)};
  position: relative;
  width: 50%;
  z-index: 2;

  img {
    border-radius: 100%;
  }

  ${media.xsmall`
    width: 100%;
  `}
`

const dot = css`
  ${position('absolute', 0, 'auto', 'auto', 0)}
  border-radius: ${rem(4)};
  content: '';
  height: ${rem(8)};
  width: ${rem(8)};
`

const TimelineButton = styled.a`
  left: 65%;
  position: absolute;
  transform: translateY(${rem(-48)});
  z-index: 2;

  ${media.xsmall`
    left: ${rem(20)};
    transform: none;
  `}

  ${media.small`
    display: none !important;
  `}
`

const TimelineList = styled.ol`
  ${position('fixed', 64, 'auto', 0, 0)}
  background: ${white};
  box-shadow:
    inset ${rem(11)} 0 0 ${white},
    inset ${rem(13)} 0 0 ${bone};
  font-size: ${rem(14)};
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: ${rem(32)} ${rem(12)} ${rem(12)} ${rem(24)};
  transform: translate(-100vw);
  width: 100vw;
  z-index: 3;
  -webkit-overflow-scrolling: touch;

  li {
    background: ${ghost};
    border-radius: ${rem(4)};
    margin-bottom: ${rem(16)};
    padding: ${rem(8)};
    position: relative;
    text-align: left;

    :before {
      ${dot}
      background: ${primary};
      transform: translate(${rem(-16)}, ${rem(14)});
    }

    :first-child {
      ${flex('row', 'center', 'space-between')}
      background: none;
      padding: 0;

      :before {
        display: none;
      }

      a {
        background: ${ghost};
        border-radius: ${rem(24)};
        color: ${black};
        height: ${rem(48)};
        margin-right: 0;
        width: ${rem(48)};

        :focus {
          box-shadow: inset 0 0 0 ${rem(2)} ${white};
        }
      }
    }
  }

  time {
    font-weight: 700;
  }

  ${is('open')`
    transform: none;
    transition: transform 500ms ease;
  `}

  ${media.xsmall`
    top: ${rem(96)};
  `}

  ${media.small`
    background: transparent;
    height: auto;
    overflow: visible;
    padding-right: 0;
    padding-top: ${rem(64)}
    position: static;
    transition: none;
    transform: translate(${rem(-12)}, ${rem(-48)});
    width: calc(100% + ${rem(16)});

    :before {
      ${dot}
      background: ${bone};
      transform: translate(${rem(8)}, ${rem(-4)});
    }

    li {
      :first-child {
        display: none;
      }

      :last-child {
        position: absolute;
        margin-bottom: ${rem(24)};
        width: calc(100% - ${rem(24)});
      }
    }
  `}

  ${media.medium`
    transform: translate(0, ${rem(-48)});
    width: calc(100% - ${rem(12)});
  `}
`

export default () => {
  const [isTimelineOpen, setIsTimelineOpen] = useState(false)
  const handleTimelineTouch = event => {
    stopEvent(event)
    setIsTimelineOpen(!isTimelineOpen)
  }
  const handleCloseTouch = event => {
    stopEvent(event)
    setIsTimelineOpen(false)
  }
  const handleWindowResize = debounce(() => {
    if (!isTimelineOpen || global.innerWidth < 640) return
    setIsTimelineOpen(false)
  }, 100)

  useEffect(() => {
    const body = document.querySelector('body')

    global.addEventListener('resize', handleWindowResize)
    if (isTimelineOpen) {
      body.style.position = 'fixed'
      body.style.overflow = 'hidden'
    } else {
      body.style.position = ''
      body.style.overflow = ''
    }
    return () => global.removeEventListener('resize', handleWindowResize)
  }, [handleWindowResize, isTimelineOpen])

  return (
    <Layout meta={{ title: 'About Me' }}>
      <Body>
        <Aside>
          <Avatar>
            <Image file="me.jpg" />
          </Avatar>
          <TimelineButton
            role="button"
            aria-label="Toggle Timeline"
            href="#"
            tabIndex={0}
            onClick={handleTimelineTouch}
            onKeyPress={handleTimelineTouch}
          >
            <Timeline />
          </TimelineButton>
          <TimelineList open={isTimelineOpen ? 1 : 0}>
            <li>
              <h2>Timeline</h2>
              <a
                role="button"
                aria-label="Close Timeline"
                href="#"
                tabIndex={0}
                onClick={handleCloseTouch}
                onKeyPress={handleCloseTouch}
              >
                <Close size={20} fill={primary} />
              </a>
            </li>
            <li>
              <time>2005</time>
              <br />
              Bachelors in <br />
              Computer Graphics
            </li>
            <li>
              <time>2005-2007</time>
              <br />
              Freelance Web Design
            </li>
            <li>
              <time>2007-2011</time>
              <br />
              Creative Director <br />
              at Ikon Marketing
            </li>
            <li>
              <time>2011-2013</time>
              <br />
              Partner <br />
              at DigitalAugment
            </li>
            <li>
              <time>2012-2013</time>
              <br />
              Board Chair, <br />
              Room to Think
            </li>
            <li>
              <time>2011-2016</time>
              <br />
              Independent <br />
              Software Contractor
            </li>
            <li>
              <time>2016-2019</time>
              <br />
              Front-End Engineer <br />
              Weedmaps
            </li>
            <li>
              <time>Present</time>
              <br />
              Exploring new opportunities.
            </li>
          </TimelineList>
        </Aside>
        <Article>
          <h1>About Me</h1>
          <blockquote>
            <p>
              <strong>
                I was recently part of an unexpected massive layoff at Weedmaps,
                and am currently{' '}
              </strong>
              <ExternalLink href="https://www.linkedin.com/in/dougwaltman/">
                Looking for new opportunities.
              </ExternalLink>
            </p>
          </blockquote>
          <p>
            I received my degree in Computer Graphics Design back in 2005. It
            turns out I didn't really enjoy designing as a full time job so I
            set out to become the best web developer I could.
          </p>
          <p>
            My first "real" job was for a marketing company, where I learned
            Wordpress and was quickly building custom themes and plugins for
            clients. After a few years I decided to get into freelancing. I
            started a small software company with a few friends where I quickly
            grew into becoming a full-stack Ruby on Rails developer. I continued
            freelancing for about five years, all through the booming evolution
            of front-end frameworks.
          </p>
          <p>
            Due to some painful, life changing events, I decided to move from
            Washington state in 2016, back my home in California. I have been
            working at Weedmaps every since, where I work with amazing people
            building remarkable products affecting positive change in the
            cannabis industry.
          </p>
          <p>
            My focus is empowering my teammates to succeed. I love CSS, React,
            as well as roller skating, video games, and dad jokes.
          </p>
          <h2>Current Tech Stack</h2>
          <table>
            <thead>
              <tr>
                <th>Tech</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>React</th>
                <td>Next.js, Mobx, Styled-Components, Gatsby</td>
              </tr>
              <tr>
                <th>Tooling</th>
                <td>
                  Babel, Webpack, Jest, RTL, Enzyme, Typescript, Flow,
                  Storybook, MDX, Lerna, GraphQL
                </td>
              </tr>
              <tr>
                <th>Integration</th>
                <td>CircleCI, Docker, Netlify, Semantic Release</td>
              </tr>
              <tr>
                <th>Services</th>
                <td>Stripe, Webtask, Mailchimp, Contentful</td>
              </tr>
            </tbody>
          </table>
          <h2>Back in the day</h2>
          <table>
            <thead>
              <tr>
                <th>Tech</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Frameworks</th>
                <td>Rails, Angular, Ember, Backbone, Ampersand, Wordpress</td>
              </tr>
              <tr>
                <th>Tooling</th>
                <td>
                  Gulp, Jasmine, Sass/Jade, RSpec, Browserify, Hapi, Sorcery
                </td>
              </tr>
              <tr>
                <th>Integration</th>
                <td>Heroku, Harp, Apipie, Sendgrid, PostgreSQL</td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <em>And even more than I can remember&hellip;</em>
                </td>
              </tr>
            </tbody>
          </table>
        </Article>
      </Body>
    </Layout>
  )
}
