import React from 'react'
import theme from 'lib/styles/theme'

export default ({
  size = 24,
  fill = theme.colors.black,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 1024 1024"
    fill={fill}
    {...rest}
  >
    <path
      d="M474 960H205c-77 0-141-70-141-160V160C64 70 128 0 205 0h486c77 0 141 70 141 160v224c0 19-13 32-32 32s-32-13-32-32V160c0-51-32-96-77-96H205c-45 0-77 45-77 96v640c0 51 32 96 77 96h269c19 0 32 13 32 32s-13 32-32 32z"
    />
    <path
      d="M698 922c-122 0-224-103-224-224s102-224 224-224a226 226 0 0 1 204 320c-6 19-25 25-44 12-13-6-26-25-13-44 13-20 13-45 13-71 0-89-71-160-160-160s-160 71-160 160 70 160 160 160c25 0 51-6 70-19 13-6 32 0 45 13 6 13 0 32-13 45-32 25-64 32-102 32z" 
    />
    <path
      d="M768 768h-90c-19 0-32-13-32-32s13-32 32-32h90c19 0 32 13 32 32s-13 32-32 32z"
    />
    <path
      d="M678 768c-19 0-32-13-32-32v-96c0-19 13-32 32-32s32 13 32 32v96c0 19-19 32-32 32zm-38-480H256c-19 0-32-13-32-32s13-32 32-32h384c19 0 32 13 32 32s-13 32-32 32zM512 480H256c-19 0-32-13-32-32s13-32 32-32h256c19 0 32 13 32 32s-13 32-32 32zM384 672H256c-19 0-32-13-32-32s13-32 32-32h128c19 0 32 13 32 32s-13 32-32 32z"
    />
  </svg>
)
